<template>
  <div>
      <v-btn class="desktop button" left absolute @click="back()"> 
        <v-icon
        dark
        left
        >
        mdi-arrow-left
      </v-icon> </v-btn>
      <v-row style="padding-top: 5px;">
        <GaleryCard :data="categories"></GaleryCard>
      </v-row>
  </div>

</template>


<script>
import GaleryCard from '@/components/GaleryCard.vue';

  export default {
    name: "GaleryView",
    data() {
        return {
            categories: [
                  {
                    name: 'Baby Galerie',
                    img: 'babygalerie.jpg',
                    link: '/babys'
                  },
                  {
                    name: 'Babybauch',
                    img: 'babybauch.jpg',
                    link: '/babybauch'
                  },
                  {
                    name: 'Familie',
                    img: 'familie.jpg',
                    link: '/familie'
                  },
                  {
                    name: 'Hochzeit',
                    img: 'hochzeit.jpg',
                    link: '/hochzeit'
                  },
                ]
        };
    },
    components: { GaleryCard },
    methods:{
      back (){
        this.$router.back();
      }
    }
}
</script>