<template>
      <div>
          <v-container fluid>
            <v-row dense style="padding-bottom: 5vh;">
              <v-col lg="2" class="desktop">
          <v-img src="../assets/lkh_logo.png"></v-img>
        </v-col>
        <v-col xs="12" md="12" lg="8">
          <introCard></introCard>
        </v-col>
      <v-spacer class="desktop"></v-spacer>
      <v-col xs="12" md="12" class="phone">
        <v-img src="../assets/lkh_logo.png"></v-img>
      </v-col>
    
    </v-row>
        <v-row
          dense
        >
          <v-col 
          v-for="(item, index) in babycards" :key="index"
          xs="12" md="4" lg="3"
          style="padding-bottom: 5vh;"
          >          
          <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-card
              :style="{'background-color': item.type ? '#fff5ff':'#e6f2ff'}"
              :class="{ 'on-hover': hover }"
              class="rounded-xl"
              :elevation="hover ? 12 : 2"
              shaped
              @click="babyview(item.id)"
              v-bind="attrs"
                v-on="on"
            >
              <v-img
              :src="item.link"
              max-width="100vw"
              max-heigth="100vh"
              ></v-img>
              <v-card-title centered>
                {{ item.name }}
              </v-card-title>
            </v-card>  
          </v-hover>
        </template>
          <span>{{ item.birthday }}</span>
        </v-tooltip>
      </v-col>  
    </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn outlined text elevation="4" @click="loadMore" :disabled="dataLeft === 0">Mehr laden ({{ dataLeft }})</v-btn>
        <v-spacer></v-spacer>
      </v-row>
    </v-container>
  </div>
</template>
  
  <script>
  import IntroCard from './introCard.vue'
  import {getData, loadBabyImageFromOverview} from "../service/awsApi.js"
  export default {
    data: () => ({
      babycards: [],
      allData: [],
      page: 1,
      count: 12,
      dataLeft: 0,
      initResp: [],
    }),
    name: 'BabyCard',
    async mounted() {
      this.loaded= false;
      try {
          const data = await getData();
          const babyResponse = data.data.reverse();
          this.dataLeft = babyResponse.length - this.count;
          if(this.initResp.length != babyResponse.length){
            this.allData = babyResponse;
            this.babycards = [];
            for(let i = 0; i < this.count; i++){
              const baby = babyResponse[i];
              baby.link = await loadBabyImageFromOverview(baby.link.toString());
              this.babycards.push(baby);
            }
            this.rerender += 1;
            this.loaded=true;
            this.initResp = babyResponse.length;
          }
        } catch (error) {
          console.error('Error fetching image URL:', error);
          // Handle the error here
        }
  },
    methods: {
        babyview(id) {
            this.$router.push({path: `/babydetail/${id}`})
        },
        async loadMore(){
          if((this.allData.length - this.count) >= 0 && (this.allData.length - this.count) >= 12){
            for(let i = this.count; i < this.count + 12; i++){
              const baby = this.allData[i];
              baby.link = await loadBabyImageFromOverview(baby.link.toString());
              this.babycards.push(baby);
              this.rerender += 1;
            }
            this.count += 12;
            this.dataLeft = this.allData.length - this.count;
          }else{
            this.dataLeft = 0;
            for(let i = this.count; i < this.allData.length; i++){
              const baby = this.allData[i];
              baby.link = await loadBabyImageFromOverview(baby.link.toString());
              this.babycards.push(baby);
              this.rerender += 1;
            }
          }
        }
    },
  components: { IntroCard }
  }
  </script>
  