<template>
  <div>
    <v-btn class="desktop button" left absolute @click="back()"> 
        <v-icon
        dark
        left
        >
        mdi-arrow-left
      </v-icon></v-btn>
    <BabyCard></BabyCard>
  </div>
</template>


<script>
import BabyCard from '@/components/BabyCard.vue';
  export default {
    name: "BabyView",
    components: { BabyCard },
  methods:{
      back (){
        this.$router.back();
      }
    }
}
</script>