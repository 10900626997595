export async function getData(){
  return (await fetch("https://okuvt34tfqwwizx5sxcwrmek4a0bltzq.lambda-url.eu-central-1.on.aws/")).json();
}

export async function loadBabyImage(url){
  const data = await fetch("https://3jrz7jgdy3ju27z6o542xinyde0azych.lambda-url.eu-central-1.on.aws/", { method: "POST", body: JSON.stringify({ "link": url})})
  const img = await data.json();
  return "data:image/jpeg;base64," + img.image;
}

export async function loadBabyImageFromOverview(url){
  const data = await fetch("https://pbsod4ruromv3he2vdhqmvfneu0hvest.lambda-url.eu-central-1.on.aws/", { method: "POST", body: JSON.stringify({ "link": url})})
  const img = await data.json();
  return "data:image/jpeg;base64," + img.image;
}

export async function getGaleryImages(path){
  return (await fetch("https://3grw52m3ymnep76kwfl6hqostu0ixnyd.lambda-url.eu-central-1.on.aws/?pictures=" + path)).json();
}
