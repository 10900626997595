<template>
    <div>
    <v-progress-circular v-if="!loaded" indeterminate></v-progress-circular>
    <ImageComponent v-if="loaded" :data="babybauch"></ImageComponent>
    </div>
</template>

<script>
import ImageComponent from '@/components/ImageComponent.vue';
import { getGaleryImages } from '@/service/awsApi';

export default {
  name: "BabyView",
  data: () => ({
    babybauch: [],
    loaded: Boolean,
  }),
  components: { ImageComponent },
  async mounted() {
    this.loaded = false;
    try {
        const data = await getGaleryImages("babybauch");
        data.forEach(element => {
            this.babybauch.push("data:image/jpeg;base64," + element.image);
        });
        this.loaded = true; 
        
    } catch (error) {
      console.error('Error fetching image URL:', error);
    }
  },
}
</script>