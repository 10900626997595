<template>
    <div class="text-center">
      <v-snackbar
        v-model="snackbar"
        timeout=-1
        color="#f6f1ee"
        multi-line
        class="desktop"
      >
      <v-row>
        <v-col lg="8" md="8">
            <a style="color: black;">{{ text }}</a> 
            <a href="/datenschutz">Datenschutzerklärung</a>
        </v-col>
        <v-col lg="2" md="2" >
            <v-btn variant="text" @click="closeDialog()">einverstanden</v-btn>
        </v-col>
      </v-row>
      </v-snackbar>

      <!-- PHONE -->

      <v-snackbar
        v-model="snackbar"
        timeout=-1
        color="#f6f1ee"
        multi-line
        class="phone"
      >
      <v-row>
            <a style="color: black; padding-left: 5px;" >{{ text }}</a> 
            <a href="/datenschutz">Datenschutzerklärung</a>
        </v-row>
    <v-row>
            <v-spacer></v-spacer>
            <v-btn variant="text" @click="closeDialog()">einverstanden</v-btn>
            <v-spacer></v-spacer>
    </v-row>

      </v-snackbar>
    </div>
  </template>

<script>

import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies, { expires: '7d'})
const cookieName = "fs-cn"
export default {
  data: () => ({
    snackbar: true,
    text: `Durch die weitere Nutzung dieser Seite sind Sie mit der Verwendung von Cookies einverstanden. `,
  }),
  mounted(){
   const cookie = this.$cookies.get(cookieName);
   if(cookie){
    this.snackbar=false;
   }
  },
  watch:{
    $route (){
        const cookie = this.$cookies.get(cookieName);
        if(!cookie){
            this.snackbar=true;
        }
    }
}, 
  methods: {
    closeDialog(){
        this.snackbar = false;
        this.$cookies.set(cookieName, "true")
    }
  }
}
</script>

<style scoped>

.theme--dark.v-btn.v-btn--has-bg{
    background-color: #b0a8a3;
}
</style>