<template>
  <div style="padding-top: 5vh;">
  <v-row dense>
    <v-spacer></v-spacer>
    <v-col centered cols="auto">
        <v-card class="rounded-xl" :class="babyData.type? 'women': 'men'">
        <v-img id="babyimg" :src="babyData.link" max-height="700px" max-width="700px"
      class="grey lighten-2"></v-img>
        <v-card-title class="text" :style="{'font-family': 'Brush Script MT', cursive }">{{ babyData.name}} {{ babyData.birthday }}</v-card-title>
      </v-card>
    </v-col>
    <v-spacer></v-spacer>
    </v-row>
    <!-- <v-row>
      <v-spacer></v-spacer>
      <v-col xs="12" md="8" lg="8">
        <IntroCard></IntroCard>
      </v-col>
      <v-spacer></v-spacer>
    </v-row> -->
    </div>
</template>

<script>
import { getData, loadBabyImage } from '../service/awsApi.js'
// import IntroCard from './introCard.vue';
export default {
    data: () => ({
        babyData: Object,
    }),
    name: "BabySheet",
    watch:{
    async $route (to){
      if(to.path.includes(`/babydetail/`)){
        const id = this.$router.currentRoute.params.id;
         try {
          const data = await getData()
            const baby = data.data.find(baby => { return baby.id == id});
            this.babyData = baby;
            this.babyData.link = await loadBabyImage(baby.link.toString())  
            this.loaded = true;
        }
        catch (error) {
            console.error("Error fetching image URL:", error);
            // Handle the error here
        }
      }
    },
}, 
    async mounted() {
        this.loaded = false;
        const id = this.$router.currentRoute.params.id;
        try {
            const data = await getData()
            const baby = data.data.find(baby => { return baby.id == id});
            this.babyData = baby;
            this.babyData.link = await loadBabyImage(baby.link.toString())  
            this.loaded = true;
        }
        catch (error) {
            console.error("Error fetching image URL:", error);
            // Handle the error here
        }
    },
    // components: { IntroCard }
}
</script>

<style scoped>
.men {
  background-color: rgb(230, 242, 255);
}

.women{
  background-color: rgb(255, 245, 255);
}

.text {
  text-justify: center;
  font-size: larger;
}

</style>