<template>
    <v-row dense>
        <v-spacer class="desktop"></v-spacer>
        <v-col lg="8">
            <v-sheet elevation="12">  
                <template>
  <div class="privacy-policy">
    <h1>Datenschutzerklärung</h1>

    <section>
      <h2>Verantwortliche Stelle</h2>
      <p>
        Verantwortlich für die Datenverarbeitung auf dieser Website ist:<br>
        <strong>Berufsfotograf Gertraud Semler – FotoStudio Semler</strong><br>
        8274 Unterbuch 175<br>
        gsemler@gmx.at
      </p>
    </section>

    <section>
      <h2>Verwendung von Cookies</h2>
      <p>
        Wir verwenden auf unserer Website Cookies, um die Funktionalität sicherzustellen und Ihr Nutzungserlebnis zu verbessern. Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden und Informationen enthalten.
      </p>
      <ul>
        <li>
          <strong>_lcp</strong>: Dieses Cookie wird bei Anfrage an unseren Webserver automatisch gesetzt und dient dem Spam-Schutz.<br>
          <strong>Speicherdauer:</strong> 400 Tage
        </li>
        <li>
          <strong>_lcp2</strong>: Dieses Cookie wird bei Anfrage an unseren Webserver automatisch gesetzt und dient dem Spam-Schutz.<br>
          <strong>Speicherdauer:</strong> 400 Tage
        </li>
        <li>
          <strong>fs-cn</strong>: Dieses Cookie wird verwendet, um die Cookie-Einstellungen des Nutzers zu speichern.<br>
          <strong>Speicherdauer:</strong> 7 Tage
        </li>
      </ul>
    </section>

    <section>
      <h2>Rechtsgrundlage</h2>
      <p>
        Die Verarbeitung der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO, sofern ein berechtigtes Interesse besteht (z. B. für technische Funktionen) oder auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO, wenn Sie Ihre Einwilligung gegeben haben.
      </p>
    </section>

    <section>
      <h2>Ihre Rechte</h2>
      <p>
        Sie haben das Recht, jederzeit Auskunft über die von uns gespeicherten Daten zu erhalten sowie deren Berichtigung oder Löschung zu verlangen. Zudem können Sie Ihre Einwilligung zur Verarbeitung jederzeit widerrufen.
      </p>
      <p>
        Bei weiteren Fragen zum Datenschutz oder zur Ausübung Ihrer Rechte können Sie uns unter der oben genannten Adresse kontaktieren.
      </p>
    </section>
  </div>
</template>



            </v-sheet>
        </v-col>
        <v-spacer class="desktop"></v-spacer>
    </v-row>
</template>


<script>
export default {
name: "DatenschutzView",
}
</script>

<style scoped>
.privacy-policy {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 20px;
}

h1 {
  font-size: 1.7em;
  margin-bottom: 1em;
}

h2 {
  font-size: 1.5em;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

ul {
  margin-left: 1.5em;
  list-style-type: disc;
}

strong {
  font-weight: bold;
}
</style>